@import "src/bpp-variables";

.ir-wfr-thumbnail-container .thumbnail-container img {
  max-width: 80px;
  min-width: 40px;
  min-height: 40px;
}

.log-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.align-left {
  text-align: left;
}

.document-icon {
  margin-left: auto;
}

.table-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  border-bottom: 1px solid #adb0b4;
  width: 98%;
}

.table-item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}

.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}

.table-cell-modal {
  flex: 0 0 65%;
  padding: 5px;

  .second-col {
    flex: 0 0 33%;
    display: flex;
    flex-direction: column;
  }
}

.table-cell {
  flex: 0 0 45%;
  padding: 5px;

  .second-col {
    flex: 0 0 53%;
    display: flex;
    flex-direction: column;
  }
}

.line-division {
  border-bottom: 1px solid #adb0b4;
  margin-left: 40px;
  margin-right: 20px;
  padding-top: 15px;
  margin-bottom: 0px;
  height: 0px;
}

.table-items-modal {
  text-align: left;
  .line-division {
    border-bottom: 1px solid #adb0b4;
    margin-left: 40px;
    margin-right: 20px;
  }
}

.failure-red {
  color: rgb(195, 11, 11);
}
.success-green {
  color: green;
}

.margin-left-40 {
  margin-left: 40px;
}

.add-location-link {
  color: rgb(76, 136, 205);
  cursor: pointer;
  align-items: center;
  margin-top: 10px;
}

.add-location-link > *:last-child {
  margin-left: 5px;
}

.delete-location {
  position: relative;
  top: -10px;
  left: 15px;
  margin-right: 5px;
  color: rgb(195, 11, 11);
  cursor: pointer;
  font-size: 17px;
}

.container-relative {
  position: relative;
  height: 0px;
}

.location-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@mixin error-message {
  color: $bpp-subtle-warning-new;
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  .error-exclamation-icon {
    margin-right: 5px !important;
  }
}

.error-message-container {
  @include error-message;
  color: #E74B3A;
}

.error-message-container {
  background-color: #d8dce1;
  position: fixed;
  bottom: 20px;
  right: 20px;
  max-width: 300px;
  z-index: 2;
  padding: 20px;

  #warning-submit {
    padding-top: 0;
  }

  .wizard-error-text {
    color: inherit;
  }
}

.location-container.error .custom-select > div {
  border: 1px solid rgb(195, 11, 11);
}

.start-end-location {
  display: flex;
  justify-content: space-between;
}

.custom-select {
  width: 70px;
  font-size: 13px;
}

.log-wire-container {
  padding-left: 50px;
}

.bold-text-modal {
  font-weight: 700;
  color: black;
}

.fit {
  width: 100%;
}

.wire-failure-modal-ir.bpp-modal {
  .modal-dialog:not(.modal-lg) {
    max-height: none;
  }
}
